import type { ICaseStudy } from '@feathr/components';

import { backgroundImages as backgrounds, logos } from './assets';

export const caseStudies: ICaseStudy[] = [
  {
    background: backgrounds.campEagle,
    link: 'https://www.feathr.co/resources/case-studies/camp-eagle-reaches-165-new-campers-through-digital-outreach',
    logo: logos.campEagle,
    organization: 'Camp Eagle',
    published_date: 'Sep 12, 2023',
    read_time: '4 min',
    title: 'Camp Eagle reaches 165 new campers through digital outreach',
  },
  {
    background: backgrounds.qcso,
    link: 'https://www.feathr.co/resources/case-studies/qcso-sells-20k-in-tickets-with-a-single-email',
    logo: logos.qcso,
    organization: 'QCSO',
    published_date: 'Nov 13, 2023',
    read_time: '1 min',
    title: 'QCSO uses Feathr to maximize concert attendance',
  },
  {
    background: backgrounds.unitedWay,
    link: 'https://www.feathr.co/resources/case-studies/united-way-increases-program-awareness-with-digital-marketing',
    logo: logos.unitedWay,
    organization: 'United Way of Asheville and Buncombe County',
    published_date: 'Nov 13, 2023',
    read_time: '1 min',
    title: 'United Way increases program awareness with Feathr',
  },
  {
    background: backgrounds.aae,
    link: 'https://www.feathr.co/resources/case-studies/aae-earns-649k-in-ticket-sales-with-feathr',
    logo: logos.aae,
    organization: 'American Association of Endodontists',
    published_date: 'Jun 12, 2024',
    read_time: '9 min',
    title: 'AAE earns $649k in ticket sales to annual conference with Feathr',
  },
];

export default caseStudies;
