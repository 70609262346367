import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SESSION_OTP_CHAR_COUNT } from '@feathr/blackbox';
import type { IStringValueInputProps } from '@feathr/components';
import { Input } from '@feathr/components';

interface IProps extends IStringValueInputProps {
  validationErrors?: string | string[];
}

function OTPInput({ onChange, value, validationErrors }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();

  return (
    <Input
      label={t('One-time password')}
      maxLength={SESSION_OTP_CHAR_COUNT}
      onChange={onChange}
      validationError={validationErrors}
      value={value}
    />
  );
}

export default OTPInput;
