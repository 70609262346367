import type { WithT } from 'i18next';
import validate from 'validate.js';

import { type ILogInResponse, Session } from '@feathr/blackbox';
import { showError, toast } from '@feathr/components';
import { isWretchError, wretch } from '@feathr/rachis';

const PASSWORD_REGEX = /^(?=.*[a-zA-Z!@#$%^&*()_+{}\[\]:;"'<>,.?/~`-]).+$/;

interface IPasswordUpdate extends WithT {
  token: string;
  password1: string;
  password2: string;
  successMessage: string;
  successRedirectPath: string;
}

export async function resetPassword({
  t,
  token,
  password1,
  password2,
  successMessage,
  successRedirectPath,
}: IPasswordUpdate): Promise<void> {
  const response = await wretch<ILogInResponse>(`${BLACKBOX_URL}reset-password/${token}`, {
    method: 'POST',
    body: JSON.stringify({ password: password1, password2 }),
    headers: { 'Content-Type': 'application/json' },
  });

  if (isWretchError(response)) {
    showError(response.error, t);
    return;
  }

  Session.token = response.data.token;

  const appUrl =
    process.env.NODE_ENV === 'production' ? `//${EXTENDER_URL}/` : '//local.feathr.app:9001/';

  toast(successMessage, { type: 'success' });
  window.location.assign(`${appUrl}${successRedirectPath ?? 'projects?message=passwordReset'}`);
}

const format = {
  pattern: PASSWORD_REGEX,
  message: 'must contain a letter or special character',
};

export function validatePassword(password: string): string[] {
  return (
    validate(
      { password },
      {
        password: {
          format,
          length: { minimum: 15 },
          presence: { allowEmpty: false, message: 'may not be empty' },
        },
      },
      { format: 'flat' },
    ) || []
  );
}

export function validatePasswordsMatch(password: string, confirmPassword: string): string[] {
  return (
    validate(
      { password, confirmPassword },
      {
        confirmPassword: {
          equality: {
            attribute: 'password',
            message: '^Passwords do not match',
            comparator: (value: string, other: string) => value === other,
          },
        },
      },
      { format: 'flat' },
    ) || []
  );
}
