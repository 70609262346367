import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import type { ICaseStudy } from '@feathr/components';
import {
  AlertV2 as Alert,
  Button,
  EAlertV2Type,
  EmailInput,
  Spinner,
  validateEmailAddress,
} from '@feathr/components';
import type { IRachisMessage } from '@feathr/rachis';
import { wretch } from '@feathr/rachis';

import { LoginForm, PageV2 } from '../../components';

import * as styles from './ForgotPasswordPage.css';

interface IProps {
  caseStudy: ICaseStudy;
}

function ForgotPasswordPage({ caseStudy }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const location = useLocation();
  const emailParam = (function (): string | null {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('email');
  })();

  const [email, setEmail] = useState(emailParam ?? '');
  const [isSending, setIsSending] = useState(false);
  const [sent, setSent] = useState(false);

  // TODO: Refactor EmailInput to be useEmailInput.

  function handleEmailChange(newValue?: string): void {
    setEmail(newValue ?? '');
  }

  async function handleSend(): Promise<void> {
    setIsSending(true);
    await wretch<IRachisMessage>(`${BLACKBOX_URL}reset-password`, {
      method: 'POST',
      body: JSON.stringify({ email }),
      headers: { 'Content-Type': 'application/json' },
    });
    setIsSending(false);
    setSent(true);
  }

  const content = sent ? (
    <Alert title={t('Link sent')} type={EAlertV2Type.success}>
      {t(
        'If an account associated with that email address exists, you will receive a password reset link shortly.',
      )}
    </Alert>
  ) : (
    <EmailInput
      autoComplete={'username'}
      className={styles.input}
      label={t('Email address')}
      name={'username'}
      onChange={handleEmailChange}
      t={t}
      type={'text'}
      validationError={validateEmailAddress(email)}
      value={email}
      wrapperClassName={styles.wrapper}
    />
  );

  return (
    <PageV2 caseStudy={caseStudy} showBackButton={true}>
      {isSending ? (
        <Spinner />
      ) : (
        <LoginForm
          actions={
            !sent ? (
              <Button
                buttonType={'submit'}
                disabled={!email || !!validateEmailAddress(email)}
                name={'send_reset_instructions'}
                onClick={handleSend}
                // Button sets internal width to auto when log in fails for some reason, this overrides.
                style={{ width: 'fit-content' }}
                type={'primary'}
              >
                {t('Send reset link')}
              </Button>
            ) : undefined
          }
          description={t('Enter your email address to receive a password reset link.')}
          footer={t('Having trouble logging in?')}
          link={{ href: 'https://help.feathr.co', text: t('Contact support') }}
          title={t('Forgot your password?')}
        >
          {content}
        </LoginForm>
      )}
    </PageV2>
  );
}

export default ForgotPasswordPage;
