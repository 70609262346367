import type { FormEvent, JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Session } from '@feathr/blackbox';
import type { ICaseStudy } from '@feathr/components';
import { Button, EmailInput, PasswordInput, toast, validateEmailAddress } from '@feathr/components';
import { getQuery, logUserEvents } from '@feathr/hooks';

import { PageV2 } from '../../components';
import LoginForm from '../../components/LoginForm';
import { redirect } from '../App';

import * as styles from './LoginPage.css';

interface IProps {
  caseStudy: ICaseStudy;
}

function LoginPage({ caseStudy }: Readonly<IProps>): JSX.Element {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailValidationError, setEmailValidationError] = useState<string | undefined>(undefined);
  const history = useHistory();
  const { t } = useTranslation();

  if (Session.isLoggedIn) {
    redirect();
  }

  function handleEmailChange(newValue?: string): void {
    setEmail(newValue ?? '');
    setEmailValidationError(undefined);
  }

  function handleEmailBlur(): void {
    setEmailValidationError(validateEmailAddress(email) || undefined);
  }

  function handlePasswordChange(newValue?: string): void {
    setPassword(newValue ?? '');
  }

  async function handleLogin(e: FormEvent<HTMLButtonElement | HTMLAnchorElement>): Promise<void> {
    const { from } = getQuery<{ from: string; next: string; return_to: string }>();
    e.preventDefault();
    try {
      await Session.logIn(email, password, undefined, undefined);
      if (!Session.isLoggedIn) {
        throw new Error('Your login token is invalid.');
      }
      logUserEvents({ 'Logged in': null });
      redirect();
    } catch (ee) {
      if (ee instanceof AggregateError) {
        const is403 = ee.errors.some((e) => e.status === 403);
        // If the response is a 403, the user is logged in but needs to verify their device using OTP.
        if (is403) {
          // Redirect to the /one-time-password page and pass along email and password.
          const url = from
            ? `/one-time-password?from=${encodeURIComponent(from)}`
            : '/one-time-password';
          history.push(url, { email, password });
          return;
        }
      }

      // If the response is a 401, the user is not logged in.
      toast(t('You were unable to sign in. Check your email and password, and try again.'), {
        type: 'error',
      });
      // eslint-disable-next-line no-console
      console.error(ee);
    }
  }

  // The previous minimum password length was 8 characters, so we need to keep that for backwards compatibility.
  const isLoginDisabled = !email || !!emailValidationError || !password || password.length < 8;

  return (
    <PageV2 caseStudy={caseStudy}>
      <LoginForm
        actions={
          <Button
            buttonType={'submit'}
            disabled={isLoginDisabled}
            name={'log_in'}
            onClick={handleLogin}
            // Button sets internal width to auto when log in fails for some reason, this overrides.
            style={{ width: 'fit-content' }}
            type={'primary'}
          >
            {t('Log in')}
          </Button>
        }
        footer={t("Don't have an account?")}
        link={{ href: 'https://feathr.co/request-a-demo', text: t('Request a demo') }}
        title={t('Welcome to the nonprofit marketing platform!')}
      >
        <EmailInput
          autoComplete={'username'}
          className={styles.input}
          label={t('Email')}
          name={'username'}
          onBlur={handleEmailBlur}
          onChange={handleEmailChange}
          t={t}
          type={'text'}
          validationError={emailValidationError}
          value={email}
        />
        <PasswordInput
          label={
            <div>
              {t('Password')}
              <Button
                buttonType={'button'}
                className={styles.forgotPassword}
                link={`/forgot-password${email ? `?email=${email}` : ''}`}
                name={'forgot_password'}
                type={'link'}
              >
                {t('Forgot password?')}
              </Button>
            </div>
          }
          onChange={handlePasswordChange}
          value={password}
        />
      </LoginForm>
    </PageV2>
  );
}

export default LoginPage;
