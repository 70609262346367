import aaeLogo from './aae_logo.svg';
import campEagleLogo from './camp_eagle_logo.svg';
import qcsoLogo from './qsco_logo.svg';
import unitedWayLogo from './united_way_logo.svg';

import aaeBackground from './aae_background.png';
import campEagleBackground from './camp_eagle_background.png';
import qcsoBackground from './qsco_background.png';
import unitedWayBackground from './united_way_background.png';

export const backgroundImages = {
  campEagle: campEagleBackground,
  qcso: qcsoBackground,
  unitedWay: unitedWayBackground,
  aae: aaeBackground,
};

export const logos = {
  campEagle: campEagleLogo,
  qcso: qcsoLogo,
  unitedWay: unitedWayLogo,
  aae: aaeLogo,
};
