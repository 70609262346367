import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isTokenValid as validateToken } from '@feathr/blackbox';
import type { ICaseStudy } from '@feathr/components';
import { AlertV2 as Alert, Button, EAlertV2Type, showError } from '@feathr/components';
import { getQuery } from '@feathr/hooks';

import type { IPasswords } from '../../components';
import { LoginForm, PageV2, PasswordInputs, resetPassword } from '../../components';

import * as styles from './InvitePage.css';

interface IProps {
  caseStudy: ICaseStudy;
}

function InvitePage({ caseStudy }: Readonly<IProps>): JSX.Element {
  const { t: token } = getQuery<{ t: string }>();
  const isTokenValid = validateToken(token);

  const [{ password1, password2, isValid: passwordsAreValid }, setPasswords] = useState<IPasswords>(
    {
      password1: '',
      password2: '',
      isValid: false,
    },
  );

  const { t } = useTranslation();

  async function handleSubmit(): Promise<void> {
    try {
      await resetPassword({
        t,
        token,
        password1,
        password2,
        successMessage: t('Your account has been created successfully.'),
        successRedirectPath: 'settings/user/profile?message=invite',
      });
    } catch (error) {
      showError(error, t);
    }
  }

  return (
    <PageV2 caseStudy={caseStudy} showBackButton={!isTokenValid}>
      <LoginForm
        actions={
          isTokenValid ? (
            <Button disabled={!passwordsAreValid} onClick={handleSubmit} type={'primary'}>
              {t('Create account')}
            </Button>
          ) : undefined
        }
        description={isTokenValid ? t('Create a password to set up your account.') : undefined}
        title={t('Welcome to Feathr!')}
      >
        {isTokenValid ? (
          <PasswordInputs onChange={setPasswords} t={t} />
        ) : (
          <Alert
            title={t('Your invitation link has expired or has already been used.')}
            type={EAlertV2Type.danger}
          >
            <Button className={styles.link} link={'/'} type={'link'}>
              {t('Return to the log in page')}
            </Button>
          </Alert>
        )}
      </LoginForm>
    </PageV2>
  );
}

export default InvitePage;
