import type { JSX } from 'react';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Session } from '@feathr/blackbox';
import type { ICaseStudy } from '@feathr/components';
import { AlertV2 as Alert, ButtonValid, Checkbox, EAlertV2Type, toast } from '@feathr/components';
import { flattenErrors, logUserEvents } from '@feathr/hooks';

import { LoginForm, OTPInput, PageV2 } from '../../components';
import { redirect } from '../App';
import { flattenErrorIfPresent, validateOTP } from './OTPPage.utils';

interface IProps {
  caseStudy: ICaseStudy;
}

function OTPPage({ caseStudy }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const location = useLocation<{ email: string; password: string }>();
  const { email, password } = location.state;

  const [OTP, setOTP] = useState('');
  const [isTrusted, setIsTrusted] = useState(false);
  const [hasAlert, setHasAlert] = useState(false);

  const validationErrors = validateOTP({
    email,
    password,
    OTP,
  });

  function handleChangeOTP(newValue?: string): void {
    setOTP(newValue ?? '');
  }

  function handleChangeIsTrusted(newValue: boolean = false): void {
    setIsTrusted(newValue);
  }

  async function handleSubmit(): Promise<void> {
    try {
      await Session.logIn(email, password, OTP, isTrusted);
      if (!Session.isLoggedIn) {
        throw new Error('Your login token is invalid.');
      }
      logUserEvents({ 'Logged in': null });
      redirect();
    } catch (error) {
      if (error instanceof AggregateError) {
        // If the response is a 403, the user is logged in but needs to verify their device using OTP.
        const is403 = error.errors.some((e) => e.status === 403);
        if (is403) {
          setHasAlert(true);
          setOTP('');
          return;
        }
      }
      throw error;
    }
  }

  async function handleResendOTP(): Promise<void> {
    try {
      await Session.resendOTP(email, password);
      if (!Session.isLoggedIn) {
        throw new Error('Your login token is invalid.');
      }
      toast(t('A new code has been sent to your email.'), {
        type: 'success',
      });
    } catch (error) {
      if (error instanceof AggregateError) {
        // If the response is a 403, the user is logged in but needs to verify their device using OTP.
        const is403 = error.errors.some((e) => e.status === 403);
        if (is403) {
          // This is expected, so we don't need to show an alert.
          return;
        }
      }
      throw error;
    }
  }

  return (
    <PageV2 caseStudy={caseStudy} showBackButton={true}>
      <LoginForm
        description={
          <Trans t={t} values={{ email }}>
            Enter the one-time password sent to <strong>{{ email }}</strong>
          </Trans>
        }
        footer={t("Didn't receive the code?")}
        link={{ onClick: handleResendOTP, text: t('Resend code') }}
        title={t('Enter your one-time password')}
      >
        {hasAlert && (
          <Alert
            description={t('Check your email for a new one-time password.')}
            title={
              <Trans t={t}>
                Your one-time password is expired <br /> or incorrect.
              </Trans>
            }
            type={EAlertV2Type.warning}
          />
        )}
        <OTPInput
          onChange={handleChangeOTP}
          validationErrors={flattenErrorIfPresent(validationErrors.OTP)}
          value={OTP}
        />
        <Checkbox
          label={t('Trust this device for 30 days')}
          onChange={handleChangeIsTrusted}
          value={isTrusted}
        />
        <ButtonValid
          buttonType={'submit'}
          errors={flattenErrors(validationErrors)}
          name={'verify_otp'}
          onClick={handleSubmit}
          type={'primary'}
        >
          {t('Verify')}
        </ButtonValid>
      </LoginForm>
    </PageV2>
  );
}

export default OTPPage;
