import classNames from 'classnames';
import type { JSX, ReactNode } from 'react';
import React from 'react';

import { Form } from '@feathr/components';

import * as styles from './LoginForm.css';

interface ILink {
  href?: string;
  text: string;
  onClick?: () => void;
}

interface IProps {
  actions?: ReactNode;
  children: ReactNode;
  description?: ReactNode;
  link?: ILink;
  footer?: ReactNode;
  title?: string;
}

function LoginForm({
  actions,
  children,
  description,
  footer,
  link,
  title,
}: Readonly<IProps>): JSX.Element {
  const descriptionElement = description instanceof Element ? description : <p>{description}</p>;
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        {title && <h1>{title}</h1>}
        {description && descriptionElement}
      </div>
      <Form label={'Login to Feathr'}>{children}</Form>
      {(actions || footer) && (
        <div className={classNames({ [styles.actions]: actions })}>
          {actions ?? null}
          {footer && (
            <p className={styles.footer}>
              {footer}
              {link && (
                <a href={link.href} onClick={link.onClick}>
                  {link.text}
                </a>
              )}
            </p>
          )}
        </div>
      )}
    </div>
  );
}

export default LoginForm;
