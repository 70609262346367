import type { JSX } from 'react';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import type { ICaseStudy } from '@feathr/components';

import ForgotPasswordPage from '../ForgotPasswordPage';
import ForgotPasswordPageV1 from '../ForgotPasswordPageV1';
import FourOhFourPage from '../FourOhFourPage/FourOhFourPage';
import InvitePage from '../InvitePage';
import InvitePageV1 from '../InvitePageV1';
import LoginPage from '../LoginPage';
import LoginPageV1 from '../LoginPageV1';
import OTPPage from '../OTPPage';
import OTPPageV1 from '../OTPPageV1';
import ResetPasswordPage from '../ResetPasswordPage';
import ResetPasswordPageV1 from '../ResetPasswordPageV1';
import caseStudies from './caseStudies';
import NotFound from './NotFound';
import RedirectTo404 from './RedirectTo404';

function getRandom(caseStudies: ICaseStudy[]): ICaseStudy {
  return caseStudies[Math.floor(Math.random() * caseStudies.length)];
}

function Routes(): JSX.Element {
  // TODO: Remove once LoginPageV2 is implemented.
  const showV1 = false;

  // Declaring case study here prevents it from being re-rendered on each route change.
  const caseStudy = getRandom(caseStudies);

  return (
    <Switch>
      <Route exact={true} path={'/'}>
        {showV1 ? <LoginPageV1 /> : <LoginPage caseStudy={caseStudy} />}
      </Route>
      <Route exact={true} path={'/one-time-password'}>
        {showV1 ? <OTPPageV1 /> : <OTPPage caseStudy={caseStudy} />}
      </Route>
      <Route path={'/404-not-found'}>
        {showV1 ? <NotFound /> : <FourOhFourPage caseStudy={caseStudy} />}
      </Route>
      <Route path={'/forgot-password'}>
        {showV1 ? <ForgotPasswordPageV1 /> : <ForgotPasswordPage caseStudy={caseStudy} />}
      </Route>
      <Route path={'/invite'}>
        {showV1 ? <InvitePageV1 /> : <InvitePage caseStudy={caseStudy} />}
      </Route>
      <Route path={'/reset-password'}>
        {showV1 ? <ResetPasswordPageV1 /> : <ResetPasswordPage caseStudy={caseStudy} />}
      </Route>
      <RedirectTo404 />
    </Switch>
  );
}

export default Routes;
