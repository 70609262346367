import { useDisclosure } from '@mantine/hooks';
import type { WithT } from 'i18next';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';

import { PasswordInput } from '@feathr/components';

import { validatePassword, validatePasswordsMatch } from './PasswordInputs.utils';

export interface IPasswords {
  password1: string;
  password2: string;
  isValid: boolean;
}

interface IProps extends WithT {
  newPasswordLabel?: string;
  newPasswordHelpText?: string;
  confirmPasswordLabel?: string;
  confirmPasswordHelpText?: string;
  onChange: (passwords: IPasswords) => void;
}

function PasswordInputs({
  t,
  newPasswordLabel = t('Enter a new password'),
  newPasswordHelpText = t(
    'Your password must be at least 15 characters and contain at least one letter or special character.',
  ),
  confirmPasswordLabel = t('Confirm your new password'),
  confirmPasswordHelpText,
  onChange,
}: Readonly<IProps>): JSX.Element {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPasswords, { toggle: toggleShowPasswords }] = useDisclosure(false);

  function handleChangeNewPassword(newValue?: string): void {
    setNewPassword(newValue ?? '');
  }

  function handleChangeConfirmPassword(newValue?: string): void {
    setConfirmPassword(newValue ?? '');
  }

  useEffect(() => {
    onChange({
      password1: newPassword,
      password2: confirmPassword,
      isValid:
        validatePassword(newPassword).length === 0 &&
        validatePasswordsMatch(newPassword, confirmPassword).length === 0,
    });
  }, [newPassword, confirmPassword]);

  return (
    <>
      <PasswordInput
        helpPlacement={'bottom'}
        helpText={newPasswordHelpText}
        label={newPasswordLabel}
        onChange={handleChangeNewPassword}
        onToggleShowPassword={toggleShowPasswords}
        shouldShowPassword={showPasswords}
        validationError={validatePassword(newPassword)?.[0]}
        value={newPassword}
      />
      <PasswordInput
        helpPlacement={'bottom'}
        helpText={confirmPasswordHelpText}
        label={confirmPasswordLabel}
        onChange={handleChangeConfirmPassword}
        onToggleShowPassword={toggleShowPasswords}
        shouldShowPassword={showPasswords}
        validationError={validatePasswordsMatch(newPassword, confirmPassword)?.[0]}
        value={confirmPassword}
      />
    </>
  );
}

export default PasswordInputs;
