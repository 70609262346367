import classNames from 'classnames';
import type { JSX, ReactNode } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { ICaseStudy } from '@feathr/components';
import { CaseStudyLayout } from '@feathr/components';

import BackButton from './BackButton';

import * as styles from './PageV2.css';

interface IProps {
  children: ReactNode;
  caseStudy: ICaseStudy;
  showBackButton?: boolean;
  contentPlacement?: 'default' | 'middle';
}

function PageV2({
  children,
  caseStudy,
  showBackButton = false,
  contentPlacement = 'default',
}: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  return (
    <CaseStudyLayout caseStudy={caseStudy} t={t}>
      <div className={classNames(styles.root, { [styles.middle]: contentPlacement === 'middle' })}>
        <div
          className={classNames({
            [styles.top]: contentPlacement === 'default',
          })}
        >
          {showBackButton && <BackButton />}
        </div>
        <div>{children}</div>
      </div>
    </CaseStudyLayout>
  );
}

export default PageV2;
