import validate from 'validate.js';

import { SESSION_OTP_CHAR_COUNT } from '@feathr/blackbox';
import { flattenError } from '@feathr/hooks';
import type { IValidateGrouped } from '@feathr/rachis';

export interface IOTPParams {
  email: string;
  password: string;
  OTP: string;
  isTrusted: boolean;
}

export interface IValidateOTP extends IValidateGrouped {
  email: string | string[] | undefined;
  password: string | string[] | undefined;
  OTP: string | string[] | undefined;
}

export function validateOTP({ email, password, OTP }: Omit<IOTPParams, 'isTrusted'>): IValidateOTP {
  const constraints = {
    email: {
      presence: true,
      email: true,
    },
    password: {
      presence: true,
    },
    OTP: {
      presence: {
        allowEmpty: false,
        message: '^One-time password is required.',
      },
      length: {
        is: SESSION_OTP_CHAR_COUNT,
        message: '^One-time password must be 8 characters.',
      },
    },
  };
  const errors: IValidateOTP =
    validate({ email, password, OTP }, constraints, {
      format: 'grouped',
    }) ?? {};

  return errors;
}

export function flattenErrorIfPresent(
  errors?: string | string[] | IValidateGrouped | IValidateGrouped[],
): string[] | undefined {
  if (errors === undefined) {
    return undefined;
  }
  return flattenError(errors);
}
