import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isTokenValid as validateToken } from '@feathr/blackbox';
import type { ICaseStudy } from '@feathr/components';
import { AlertV2 as Alert, Button, EAlertV2Type, showError } from '@feathr/components';
import { getQuery } from '@feathr/hooks';

import type { IPasswords } from '../../components';
import { LoginForm, PageV2, PasswordInputs, resetPassword } from '../../components';

import * as styles from './ResetPasswordPage.css';

interface IProps {
  caseStudy: ICaseStudy;
}

function ResetPasswordPage({ caseStudy }: Readonly<IProps>): JSX.Element {
  const { t: token } = getQuery<{ t: string }>();
  const isTokenValid = validateToken(token);
  const { t } = useTranslation();

  const [{ password1, password2, isValid: passwordsAreValid }, setPasswords] = useState<IPasswords>(
    {
      password1: '',
      password2: '',
      isValid: false,
    },
  );

  async function handleSubmit(): Promise<void> {
    try {
      await resetPassword({
        t,
        token,
        password1,
        password2,
        successMessage: t('Your password has been reset.'),
        successRedirectPath: 'projects?message=passwordReset',
      });
    } catch (error) {
      showError(error, t);
    }
  }

  return (
    <PageV2 caseStudy={caseStudy} showBackButton={true}>
      <LoginForm
        actions={
          isTokenValid ? (
            <Button disabled={!passwordsAreValid} onClick={handleSubmit} type={'primary'}>
              {t('Reset password')}
            </Button>
          ) : undefined
        }
        description={isTokenValid ? t('Reset your password to access your account.') : undefined}
        title={t('Reset your password')}
      >
        {isTokenValid ? (
          <PasswordInputs onChange={setPasswords} t={t} />
        ) : (
          <Alert
            title={t('Your password reset link has expired or has already been used.')}
            type={EAlertV2Type.danger}
          >
            <Button className={styles.link} link={'/forgot-password'} type={'link'}>
              {t('Send reset instructions')}
            </Button>
          </Alert>
        )}
      </LoginForm>
    </PageV2>
  );
}

export default ResetPasswordPage;
