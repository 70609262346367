import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from '@feathr/components';

import * as styles from './BackButton.css';

function BackButton(): JSX.Element {
  const { t } = useTranslation();
  return (
    <Button
      buttonType={'button'}
      className={styles.button}
      link={'/'}
      prefix={<Icon icon={faChevronLeft} />}
      type={'naked'}
    >
      {t('Back to login')}
    </Button>
  );
}

export default BackButton;
