import type { JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { ICaseStudy } from '@feathr/components';
import { Button } from '@feathr/components';

import { LoginForm, PageV2 } from '../../components';

interface IProps {
  caseStudy: ICaseStudy;
}

function FourOhFourPage({ caseStudy }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  return (
    <PageV2 caseStudy={caseStudy} contentPlacement={'middle'}>
      <LoginForm
        description={
          <Trans t={t}>
            The page you are looking for doesn't exist
            {/* Line break prevents widow word on new line */}
            <br />
            or has been moved.
          </Trans>
        }
        title={t('Page not found')}
      >
        <Button href={'/'}>{t('Back to home page')}</Button>
      </LoginForm>
    </PageV2>
  );
}

export default FourOhFourPage;
